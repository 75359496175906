import { transform } from "framer-motion";
import React , { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";


import $ from 'jquery';

const key = 1;

const Decorslist = () => {

  const loadMore = async () => {


    $('.tabNav ul').each(function() {
      var num = $(this).find('li').length;
      if (num > 9) {
        $(this).find('li').addClass('hidden');
        $(this).find('li').addClass('toggle');
        $(this).find('li:first').removeClass('hidden');
        $(this).find('li:first').removeClass('toggle');
        $(this).find('li:first').addClass('toggled');
        $(this).find('li:nth-child(1)').removeClass('hidden');
        $(this).find('li:nth-child(1)').removeClass('toggle');
        $(this).find('li:nth-child(1)').addClass('toggled');
        $(this).find('li:nth-child(2)').removeClass('hidden');
        $(this).find('li:nth-child(2)').removeClass('toggle');
        $(this).find('li:nth-child(2)').addClass('toggled');
        $(this).find('li:nth-child(3)').removeClass('hidden');
        $(this).find('li:nth-child(3)').removeClass('toggle');
        $(this).find('li:nth-child(3)').addClass('toggled');
        $(this).find('li:nth-child(4)').removeClass('hidden');
        $(this).find('li:nth-child(4)').removeClass('toggle');
        $(this).find('li:nth-child(4)').addClass('toggled');
        $(this).find('li:nth-child(5)').removeClass('hidden');
        $(this).find('li:nth-child(5)').removeClass('toggle');
        $(this).find('li:nth-child(5)').addClass('toggled');
        $(this).find('li:nth-child(6)').removeClass('hidden');
        $(this).find('li:nth-child(6)').removeClass('toggle');
        $(this).find('li:nth-child(6)').addClass('toggled');
        $(this).find('li:nth-child(7)').removeClass('hidden');
        $(this).find('li:nth-child(7)').removeClass('toggle');
        $(this).find('li:nth-child(7)').addClass('toggled');
        $(this).find('li:nth-child(8)').removeClass('hidden');
        $(this).find('li:nth-child(8)').removeClass('toggle');
        $(this).find('li:nth-child(8)').addClass('toggled');
        $(this).find('li:nth-child(9)').removeClass('hidden');
        $(this).find('li:nth-child(9)').removeClass('toggle');
        $(this).find('li:nth-child(9)').addClass('toggled');
        $(this).find('li:nth-child(10)').removeClass('hidden');
        $(this).find('li:nth-child(10)').removeClass('toggle');
        $(this).find('li:nth-child(10)').addClass('toggled');
      }
    });

    $('.more').on('click', function() {

        $('.toggle').toggleClass('hidden');

    });

  };

  useEffect(() => {
    
    $('input:checkbox').on('change', function () {

      var resultUrl = "";

      var url_all = "https://staging.alnoormdf.com/alnoor/all-products";
        
      var mc_array = [];
      var sc_array = [];
        
      var api = "https://staging.alnoormdf.com/api/all-products?";
    
      mc_array = [];
      sc_array = [];

      $("input:checkbox:checked").each(function() {
        if ($(this).hasClass('m-cat')){
          mc_array.push($(this).attr('rel'));
        }
        if ($(this).hasClass('s-cat')){
          sc_array.push($(this).attr('rel'));
        }
      });

      resultUrl = api + "c_id=" + mc_array.join(",") + "&sc_id=" + sc_array.join(",")
      //alert(url);

      setUrl(resultUrl);

      //console.log(resultUrl);

      if (resultUrl == "https://staging.alnoormdf.com/api/all-products?c_id=&sc_id=") {
        resultUrl = "https://staging.alnoormdf.com/alnoor/all-products";
      }

      const getallproducts = async () => {

        console.log(resultUrl);
        const res = await axios.get(resultUrl);
      
        if (res.status === 200) {
        //console.log(res.data.data[0]);
        console.log('Products Data:');
          console.log(res.data.data)
          setallproducts(res.data.data)
          
        }

      };

      getallproducts();

      if ($(window).width() < 768) {

        $('a').removeAttr('target');

      }

      // const removeRepeatedProducts = async () => {

      //   var dataId;
      //   var otherDataId;

      //   $('li').each(function() {
      //     dataId = $(this).attr('data-id');
      //     otherDataId = $(this).siblings().attr('data-id');
      //     if (otherDataId === dataId) {
      //       $(this).remove();
      //     }
      //   });

      // };

      // removeRepeatedProducts();

    });

    const tactile = document.querySelectorAll('.tactile');

    for (let i = 0; i < tactile.length; i++) {
      tactile[i].addEventListener('click', function() {
        const checkbox = this.querySelectorAll('input[type="checkbox"]:checked');
        if (checkbox.length > 0) {
          const tactileSfCheckbox = document.querySelectorAll('.tactile-sf input[type="checkbox"]');
          for (let j = 0; j < tactileSfCheckbox.length; j++) {
            tactileSfCheckbox[j].checked = false;
          }
          const tactileSf = document.querySelector('.tactile-sf');
          tactileSf.style.display = 'block';
        } else {
          this.checked = false;
          const tactileSf = document.querySelector('.tactile-sf');
          tactileSf.style.display = 'none';
        }
      });
    }

    
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const filter = searchParams.get('filter');

  const [allproducts, setallproducts] = useState([]);

  const [allcategories, setallcategories] = useState([]);

  const [url, setUrl] = useState("https://staging.alnoormdf.com/alnoor/all-products");

  useEffect(() => {
    
    getallproducts();
    getallcategories();
    jQueryFilterTags();
    filterLink();

    
    if ($(window).width() < 768) {

      $('a').removeAttr('target');

    }
    
  }, []);

  const getallproducts = async () => {

    const res = await axios.get(url);
    // console.log(url);
    if (res.status === 200) {
    //console.log(res.data.data[0])
    console.log('Products Data:');
      console.log(res.data.data)
      setallproducts(res.data.data)
      loadMore();
      
      if ($(window).width() < 768) {

        $('a').removeAttr('target');

      }
    }

  };

  const getallcategories = async () => {

    const res = await axios.get(`https://staging.alnoormdf.com/alnoor/all-categories`);
    if (res.status === 200) {
    console.log(res.data.data[0])
    console.log('Categories Data:');
    console.log(res.data.data);
      //console.log(res)
      setallcategories(res.data.data);

      
      if ($(window).width() < 768) {

        $('a').removeAttr('target');

      }
    }

  };

  const jQueryFilterTags = () => {
      
    $("#filter").keyup(function () {
      
      //$(".tags input:checkbox").prop('checked', false);
      $(".sub-filters").fadeOut();
        // Retrieve the input field text and reset the count to zero
        var filter = $(this).val(),
            count = 0;

        // Loop through the comment list
        $("li").each(function () {

            // If the list item does not contain the text phrase fade it out
            if ($(this).text().search(new RegExp(filter, "i")) < 0) {
                $(this).fadeOut(0).addClass('hidden');
                $(this).attr('style', 'display:none !important');

                // Show the list item if the phrase matches and increase the count by 1
            } else {
              $(this).show().removeClass('hidden'); 
              $(this).css({ 'display' : '' });
              //count++;
            }
        });

        // Update the count
        // var numberItems = count;
        // $("#filter-count").text("Number of Comments = " + count);
    });


    $(".h-gloss").on('click', function () {

        if ($(this).find('input:checkbox:checked').length > 0) {
            $(".h-gloss-sf").find('input[type="checkbox"]').prop("checked", false);
            $(".h-gloss-sf").fadeIn();
        } else {
            $(this).prop("checked", false);
            $(".h-gloss-sf").fadeOut();
        }

    });

    // $(".tactile").on('click', function () {

    //   if ($(this).find('input:checkbox:checked').length > 0) {
    //       $(".tactile-sf").find('input[type="checkbox"]').prop("checked", false);
    //       $(".tactile-sf").fadeIn();
    //   } else {
    //       $(this).prop("checked", false);
    //       $(".tactile-sf").fadeOut();
    //   }

    // });

  }

  const filterLink = async () => {

    if( filter === 'select' ) {

      $(".select").find('input[type="checkbox"]').click();

    }

    if( filter === 'tactile' ) {

      $(".tactile").find('input[type="checkbox"]').click();

    }


  };

  useEffect(() => {

    // Update Page title tag  
    document.title = "Al-Noor | Decor Library";

    // Select the meta tag with a name attribute of "property"
    const socialPageTitle = document.querySelector('meta[property="og:title"]');

    // Update the content attribute of the meta tag
    socialPageTitle.setAttribute('content', "Al-Noor | Decor Library");

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "Find the best suited decor as per your requirements from among the vast collection list of our decors.");

  }, []);

  return (
      
  <div>
      

  <div style={{boxShadow:"-7px 7px 45px 0px rgba(0, 0, 0, 0.1)"}}>
        <Header />
  </div>

    <div style={{ width: "100%", marginBottom: "20px", marginTop:"70px" }}>
      <p style={{fontSize:"22px",fontWeight:"bold",fontFamily:"AvenirRoman",color:"#4b4b4b",textAlign:"center"}}>
        The Decors Library
      </p>
    </div>
        
    <div style={{width: "100%", marginLeft: "auto", marginRight: "auto", marginBottom: "0px"}}>


      <div className="decorslist-search-categories" style={{marginLeft: "auto", marginRight: "auto", marginBottom: "0px"}}>
      <form id="live-search" action="" class="styled" method="post">

      <div>
        <input type="text" id="filter" placeholder="Search by code....." style={{width: "100%",height: "38px",border: "2px solid black", borderRadius: "12px", padding: "15px", paddingTop: "14.5px"}} />
      </div>

      <div className="d-flex" style={{width: "100%", marginLeft: "auto", marginRight: "auto", marginTop: "15px", paddingLeft: "1em"}}>

        <div class="tags" style={{marginLeft: "auto", marginRight: "auto"}}>

            <div class="form-check form-check-inline">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox1" rel="16" value="option1" />
              <label class="form-check-label" for="inlineCheckbox1">S Gloss</label>
            </div>

            <div class="form-check form-check-inline tactile">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox2" rel="" value="option2" />
              <label class="form-check-label" for="inlineCheckbox2">Tactile</label>
            </div>

            <div class="form-check form-check-inline h-gloss">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox3" rel="18" value="option1" />
              <label class="form-check-label" for="inlineCheckbox3">High Gloss</label>
            </div>

            <div class="form-check form-check-inline">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox4" rel="19" value="option2" />
              <label class="form-check-label" for="inlineCheckbox4">Natural Matte</label>
            </div>

            <div class="form-check form-check-inline select">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox5" rel="20" value="option1" />
              <label class="form-check-label" for="inlineCheckbox5">Select</label>
            </div>

            <div class="form-check form-check-inline">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox6" rel="21" value="option2" />
              <label class="form-check-label" for="inlineCheckbox6">Wood Grain</label>
            </div>

            <div class="form-check form-check-inline">
              <input  class="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox7" rel="22" value="option1" />
              <label class="form-check-label" for="inlineCheckbox7">Solid</label>
            </div>

            <center>
            <div class="d-block text-center mt-2" style={{width: "fit-content"}}>
            <center>
              
              <div class="sub-filters tactile-sf mt-2" style={{display: "none"}}>
                
                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="5" rel="5" value="option1" />
                    <label class="form-check-label" for="5">Natural Veneer</label>
                  </div>
                </div>

                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="6" rel="6" value="option1" />
                    <label class="form-check-label" for="6">Brushed Oak</label>
                  </div>
                </div>

                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="7" rel="7" value="option1" />
                    <label class="form-check-label" for="7">Vintage</label>
                  </div>
                </div>

                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="8" rel="8" value="option1" />
                    <label class="form-check-label" for="8">Calcite</label>
                  </div>
                </div>

                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="9" rel="9" value="option1" />
                    <label class="form-check-label" for="9">Textile</label>
                  </div>
                </div>

              </div>

              {/* <div class="sub-filters h-gloss-sf mt-2" style={{display: "none"}}>

                <div class="d-inline mr-2 ml-2">
                  <div class="form-check form-check-inline">
                    <input  class="form-check-input mt-1 s-cat" type="checkbox" id="2" rel="3" value="option1" />
                    <label class="form-check-label" for="2">Richard Sim</label>
                  </div>
                </div>

              <div class="d-inline mr-2 ml-2">
                <div class="form-check form-check-inline">
                  <input  class="form-check-input mt-1 s-cat" type="checkbox" id="3" rel="4" value="option1" />
                  <label class="form-check-label" for="3">Kato Rose</label>
                </div>
              </div>
              
              </div> */}

          </center>
          </div>
          </center>

        </div>

      </div>

      </form>
      </div>
              
    </div>


    <div className="decorslist-tiles" style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>

      <div className="row pt-5 justify-content-center dlist-container tabNav" style={{width: "100%,", marginLeft: "auto", marginRight: "auto", marginBottom: "40px"}}>

        <ul className="decorList text-center">



          {Object.keys(allproducts).map((i, j) =>  (


            <li style={{position: "relative"}} data-id={allproducts[i].product_id}>

            <div class="decore-tile-container">

              <a href={"/decorsdetail/"+allproducts[i].product_slug} target="_blank">
                <img class="decore-tile-image" src={"https://staging.alnoormdf.com/"+allproducts[i].thumbnail_image} alt={allproducts[i].product_name}></img>
              </a>

              <div class="decore-tile-label">

                <p class="decore-tile-label-text">
                  <a href={"/decorsdetail/"+allproducts[i].product_slug} target="_blank">
                    {allproducts[i].product_name}
                  </a>
                </p>

              </div>

            </div>


              

              </li>

          ))}

        </ul>

      </div>

    </div>

    <div className="text-center more" style={{cursor: "pointer"}}>
      <center>
    
        <motion.div style={{border: "2px solid #474b4e", borderRadius: "10px", padding: "6px", width: "19px", height: "38px"}}>
          <motion.div
            style={{width: "3px", height: "3px", borderRadius: "10px", backgroundColor: "#474b4e"}}
            animate={{
              y: [
                0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 20, 18, 16, 14, 12, 10,
                8, 6, 4, 2, 0
              ],
              x: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0
              ],
            }}
            transition={{ duration: 2.5, yoyo: Infinity }}
          ></motion.div>
        </motion.div>

        <i style={{fontSize: "20px", rotate: "90deg", marginLeft: "-0.1px"}} class="fa-solid fa-angle-right fa-angle-right2"></i>

      </center>
    </div>

  <Footer/>
        
  </div>

  );
};

export default Decorslist;



