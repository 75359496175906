import React , { useEffect } from "react";
import Layout from '../components/Layout/Index'
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {

  useEffect(() => {

    // Update Page title tag  
    document.title = "Al-Noor | Contact Us";

    // Select the meta tag with a name attribute of "property"
    const socialPageTitle = document.querySelector('meta[property="og:title"]');

    // Update the content attribute of the meta tag
    socialPageTitle.setAttribute('content', "Al-Noor | Contact Us");

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "Adress; Al-Noor Building, 96-A, SMCHS, Karachi. Email: info@alnoorlasani.com. Phone: 00922134389272, 00922134559853");

  }, []);

  return (
    <>

      <div className='headerboxshadow'>
        <Header />
      </div>    
      
      <div className='contactbody'>
    
        <div className='contactbody2' >
        <p className='subheadmain' >Contact Us</p>
        <p className='contacttext' >info@alnoorlasani.com</p>
      
        <p className='contacttext'>+92 21 34389272</p>
        <p className='contacttext'>+92 21 34559853</p>
        <br/>
        <p className='subheadmain' >Head Office</p> 
        <p className='contacttext'>Al-Noor Building,</p>
        <p className='contacttext'>96-A, SMCHS, Karachi.</p>
        
        <br/>
      


        </div>
    </div>

      <Footer  />
      </>
  )
}

export default About