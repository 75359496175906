import React , { useEffect } from "react";
import Layout from '../components/Layout/Index'
import Header from "../components/Header";
import Footer from "../components/Footer";

const OurDesignStudios = () => {


  useEffect(() => {

    // Update Page title tag  
    document.title = "Al-Noor | Technical Specifications";

    // Select the meta tag with a name attribute of "property"
    const socialPageTitle = document.querySelector('meta[property="og:title"]');

    // Update the content attribute of the meta tag
    socialPageTitle.setAttribute('content', "Al-Noor | Technical Specifications");

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "UV coating line details...");

  }, []);


  return (
    
    <>
      <div className='headerboxshadow' >
        <Header />
      </div>
      <p className='subheadmain' >Technical Specifications</p>
      <div style={{ minHeight: "607px"}}>



        <img className='imgtop'
          src="/images/technical-specs-top.webp"
          alt="Alnoor"
          objectFit="cover"
        />
<p className='headbottom' >UV Coating Line</p>


<img className='imgbottom'
          
          src="/images/technical-specs-bottom.webp"
          alt="Alnoor"
          objectFit="cover"
        />
 

      </div>

      <Footer />
    </>
  )
}

export default OurDesignStudios