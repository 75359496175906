import React , { useEffect } from "react";
import Layout from '../components/Layout/Index'
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {

  useEffect(() => {

    // Update Page title tag  
    document.title = "Al Noor | About Us";

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "Al-Noor Group of companies first began its operations with trading activities in the late nineteenth century in Mauritius and subsequently offices were set up in India, Sri Lanka and Burma to handle trading of sugar, rice and jute. After the partition of the sub-continent, the Group established Noori Trading Corporation (Pvt) Limited in Karachi, Pakistan to handle the trading of general merchandise and in 1960 Noori Sugar Factory was established with a cane research farm in Moro/Nawabshah District along with a small sugar-manufacturing unit.");

  }, []);

  return (
    <>
        <div className='headerboxshadow'>
      <Header />
      </div>
    
    <div className='aboutbody aboutbodyresp mt-4'>
    
    <div className='mr-3 text-center'>
    <img className='aboutimg aboutimgresp aboutimgrespmob'
          src="/images/about.webp"
          alt="Alnoor"
          objectFit="cover"
        />
    </div>
    <div style={{marginLeft:"20px"}}>
    <p className='contactheadmain headmainresp' >About Us</p>
    <p className='abouttxt respabout'>Al-Noor Group of companies first began its operations with trading
activities in the late nineteenth century in Mauritius and subsequently
offices were set up in India, Sri Lanka and Burma to handle trading
of sugar, rice and jute.</p>
<p className='abouttxt respabout' >After the partition of the sub-continent, the Group established Noori
Trading Corporation (Pvt) Limited in Karachi, Pakistan to handle the
trading of general merchandise and in 1960 Noori Sugar Factory was
established with a cane research farm in Moro/Nawabshah District
along with a small sugar-manufacturing unit.</p>
<p className='abouttxt1' >At present, Al-Noor Group has following companies under its umbrella:</p>
    




<p className='abouttxtbold' ><span className='about-dash'>- </span>Al-Noor Sugar Mills Ltd
<p className='abouttxtshadow'> Al-Noor MDF (Medium Density Fiber) Board division</p>
</p>


<p className='abouttxtbold' ><span className='about-dash'><span className='about-dash'>- </span></span>Shahmurad Sugar Mills Ltd
<p className='abouttxtshadow' >   Shahmurad Distillery</p>
</p>


<p className='abouttxtshadowbold' ><span className='about-dash'>- </span>Reliance Insurance Company Limited

</p>



<p className='abouttxtshadowbold' ><span className='about-dash'>- </span>First Al-Noor Modaraba

</p>


<p className='abouttxt respabout' >In 1971 Al-Noor Sugar Mills Limited was commissioned and the
Company was listed on the Karachi Stock Exchange. The principal
activities of the Company are manufacturing sugar, medium density
fiber (MDF) board, power generation and its sale.</p>
    </div>
    </div>

      <Footer />
      </>
  )
}

export default About