import React , { useEffect } from "react";
import Layout from '../components/Layout/Index'
import Header from "../components/Header";
import Footer from "../components/Footer";

const OurDesignStudios = () => {


  useEffect(() => {

    // Update Page title tag  
    document.title = "Al-Noor | Our Design Studios";

    // Select the meta tag with a name attribute of "property"
    const socialPageTitle = document.querySelector('meta[property="og:title"]');

    // Update the content attribute of the meta tag
    socialPageTitle.setAttribute('content', "Al-Noor | Our Design Studios");

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "Karachi Design Studio - 24-C, 7th Commercial Lane, Main Khayaban-e-Bahria, Phase IV , DHA, Karachi.");

  }, []);


  return (
    <>
      <div style={{boxShadow:"-7px 7px 45px 0px rgba(0, 0, 0, 0.1)"}}>
        <Header />
      </div>
      <p className='Submainhead' >Our Design Studios</p>
      <div className='Imgheight'>


        <img className='imgsize hero-banner-desktop'
          src="/images/ostdban.webp"
          alt="Alnoor"
          objectFit="cover"
        />

      <div className='hero-banner-mobile mb-5' style={{backgroundImage: "url('/images/ostdban.webp')", backgroundPosition: "90% 0", backgroundRepeat: "no-repeat", height: "350px"}}></div>

        <div className='upfooter' >


          <div className='upfootertext'>
            <p className='upfootertext1' >Karachi Design Studio</p>
            <p className='upfootertext2' >24-C, 7th Commercial Lane,<br />
              Main Khayaban-e-Bahria,<br />
              Phase IV , DHA, Karachi.<br /></p>
            <a href="https://goo.gl/maps/wDhR5uoHAQGnhThf9" target="_blank">
            <img className='imgupfooter'
              src="/images/pin-location.webp"
              alt="Alnoor"
              objectFit="cover"
              style={{display: "block"}}
            />
            </a>
            <p className='upfooternum' >+92 21 372 372 05</p>


          </div>
          
          <img className='imgupfooter1'
            src="/images/ods-shadow.webp"
            alt="Alnoor"
            objectFit="cover"
          />
          
          <div className='upfootertext' style={{ paddingRight:"50px"}}>
            <p className='upfootertext1'>Lahore Design Studio</p>
            <p className='upfootertext2'>7-A, Sector XX, Phase 3,<br />
             
              DHA, Lahore.<br /></p>
            <a href="https://goo.gl/maps/7UcJreS2GjDT7Jrf8" target="_blank">
            <img className='imgupfooter'
            
              src="/images/pin-location.webp"
              alt="Alnoor"
              objectit="cover"
              style={{display: "block"}}
              
            />
            </a>
            <p className='upfooternum'>+92 42 37185113</p>
            <p className='upfooternum' style={{ marginTop: "-5px" }}>+92 42 37185114</p>







          </div>



        </div>

      </div>

      <Footer />
    </>
  )
}

export default OurDesignStudios