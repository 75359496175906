import React , { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from 'axios';
import { Link, useParams } from "react-router-dom";

import $ from 'jquery';

const style1 = {display: "none"};

const DecorsDetail = () => {
  
  let { product_slug } = useParams();


  const [productdetails, setproductdetails] = useState([]);

  const [pageTitle, setPageTitle] = useState('');

  const [pageMeta, setPageMeta] = useState(''); 

  const [PageThumb, setPageThumb] = useState('');

  useEffect(() => {
   
    getoneprod();
    getoneprod1();
    topImgthumb();
    topImgthumb2();
    subImgFix();
    imageZoom();
    imageChange();
    subImage();
    jQueryGeneral();

  }, []);

  useEffect(() => {

    if (pageTitle !== '') {

      document.title = pageTitle;

      // Select the meta tag with a name attribute of "property"
      const socialPageTitle = document.querySelector('meta[property="og:title"]');

      // Update the content attribute of the meta tag
      socialPageTitle.setAttribute('content', pageTitle);

    }

  }, [pageTitle]);

  useEffect(() => {

    if (pageMeta !== '') {

      // Select the meta tag with a name attribute of "description"
      const metaDescription = document.querySelector('meta[name="description"]');

      // Update the content attribute of the meta tag
      metaDescription.setAttribute('content', pageMeta);

      // Select the social meta tag with a name attribute of "property"
      const socialMetaDescription = document.querySelector('meta[property="og:description"]');

      // Update the social content attribute of the meta tag
      socialMetaDescription.setAttribute('content', pageMeta);

    }
  }, [pageMeta]);

  useEffect(() => {

    // Get the current URL of the page
    const currentUrl = window.location.href;

    // Create a new URL object from the URL string
    const urlObj = new URL(currentUrl);

    // Get the hostname property of the URL object
    const domain = urlObj.hostname;

    if (PageThumb !== '') {

      // Select the social meta tag with a name attribute of "property"
      const socialMetaDescription = document.querySelector('meta[property="og:image"]');

      // Update the social content attribute of the meta tag
      socialMetaDescription.setAttribute('content', domain+'/'+PageThumb);

    }
  }, [PageThumb]);



  const topImgthumb = async () => {


    $('.cc-popup img').on('click', function(){

      var img = $(this).attr('src');

      $(this).removeClass('active-slide');
      $(this).removeClass('previous');
      $(this).removeClass('next');
    
      $(this).prev().addClass('previous');
      $(this).addClass('active-slide');
      $(this).next().addClass('next');

      //$('.decimg img').attr('src', img);
      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);

      if ($(window).width() < 768) {
        $('.cc-popup img').on('click', function(){
           $('#produto img').removeClass('zoomed');
        });
      }

    });
    
    $('.next').on('click', function(){

      var img = $(this).attr('src');

      $('.cc-popup img').removeClass('active-slide');
      $('.cc-popup img').removeClass('previous');
      $('.cc-popup img').removeClass('next');
    
      $(this).prev().addClass('previous');
      $(this).addClass('active-slide');
      $(this).next().addClass('next');
      
      //$('.decimg img').attr('src', img);
      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);

      if ($(window).width() < 768) {
        $('.cc-popup img').on('click', function(){
           $('#produto img').removeClass('zoomed');
        });
      }

    });

    $('.previous').on('click', function(){

      var img = $(this).attr('src');

      $('.cc-popup img').removeClass('active-slide');
      $('.cc-popup img').removeClass('previous');
      $('.cc-popup img').removeClass('next');
    
      $(this).prev().addClass('previous');
      $(this).addClass('active-slide');
      $(this).next().addClass('next');
      
      //$('.decimg img').attr('src', img);
      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);

      if ($(window).width() < 768) {
        $('.cc-popup img').on('click', function(){
           $('#produto img').removeClass('zoomed');
        });
      }

    });

  }


  const subImgFix = async () => {

    $('.decorsubimg div').on('mousemove', function(){

      // $(this).removeClass('active-slide2');
      // $(this).addClass('active-slide2');

      $(this).removeClass('previous2');
      $(this).removeClass('next2');
    
      $(this).prev().addClass('previous2');
    
      if($(this).prev().hasClass('previous2')){
        $(this).addClass('next2');
      }
      else{
        $(this).addClass('previous2');
      }

      $(this).next().addClass('next2');
      
    });

  }

  const topImgthumb2 = async () => {

    $('.decorsubimg img').on('click', function(){

      var img = $(this).attr('src');

      $(this).removeClass('active-slide2');
      $(this).removeClass('previous2');
      $(this).removeClass('next2');
    
      $(this).prev().addClass('previous2');
      $(this).addClass('active-slide2');
      $(this).next().addClass('next2');

      //$('.decimg img').attr('src', img);
      $('.product_image').attr('src', img);
      //$('.zoomImg').attr('src', img);

    });
    
    $('.next2').on('click', function(){

      var img = $(this).attr('data-img');

      $('.decorsubimg div').removeClass('active-slide2');
      $('.decorsubimg div').removeClass('previous2');
      $('.decorsubimg div').removeClass('next2');
    
      $(this).prev().addClass('previous2');
      $(this).addClass('active-slide2');
      $(this).next().addClass('next2');
      
      //$('.decimg img').attr('src', img);
      $('.product_image').attr('src', img);
      //$('.zoomImg').attr('src', img);

    });

    $('.previous2').on('click', function(){

      var img = $(this).attr('data-img');

      $('.decorsubimg div').removeClass('active-slide2');
      $('.decorsubimg div').removeClass('previous2');
      $('.decorsubimg div').removeClass('next2');
    
      $(this).prev().addClass('previous2');
      $(this).addClass('active-slide2');
      $(this).next().addClass('next2');
      
      //$('.decimg img').attr('src', img);
      $('.product_image').attr('src', img);
      //$('.zoomImg').attr('src', img);

    });

  }

  const imageChange = async () => {

    $('.decorbrandlogosbody .r-product').on('click', function(){

      var img = $(this).find('.r-image').attr('src');
      var title = $(this).find('.r-detail').find('.r-title').text();

      var img2 = $(this).find('.r-detail').find('.r-subimg1').text();
      var img3 = $(this).find('.r-detail').find('.r-subimg2').text();

      $('.decorheadmain span').text(title); 

      $('.subimg1').attr('data-img', img2);
      $('.subimg2').attr('data-img', img3);

      $('.subimg1').attr('style', 'box-shadow: none; background-image:'+'url("'+img2+'"); box-shadow: 0 3px 10px rgb(0 0 0 / 20%);');
      $('.subimg2').attr('style', 'box-shadow: none; background-image:'+'url("'+img3+'"); display:none');

      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);

      $('.decorheadmain span').text(title);
      
      $('.decimg img').attr('src', img);

      $('.main-image').attr('style', 'overflow: hidden; box-shadow: 2px 5px 8px 4px rgba(0, 0, 0, 0.4); border-radius: 50px; background-position: center center; background-image:'+'url("'+img+'");');

      $('.main-image').attr('data-src', img);

      //$('.main-image img').attr('src', img);

      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);

      $('.close2').addClass('d-none');
      $('.popup-top').removeClass('d-none');
      $('.dds-control2').addClass('d-none');

      if ($(window).width() < 768) {
        $('.cc-popup img').on('click', function(){
           $('#produto img').removeClass('zoomed');
        });
      }

    });

    $('.decorbrandlogosbody .m-product').on('click', function(){

      var img0 = $(this).find('.m-image').attr('src');
      var title0 = $(this).find('.m-detail').find('.m-title').text();

      var img02 = $(this).find('.m-detail').find('.m-subimg1').text();
      var img03 = $(this).find('.m-detail').find('.m-subimg2').text();

      $('.subimg1').attr('data-img', img02);
      $('.subimg2').attr('data-img', img03);

      //$('.subimg1').attr('style', 'background-image:'+'url("'+img02+'");');    
      
      $('.subimg1').attr('style', 'background-image:'+'url("'+img02+'"); box-shadow: 0 3px 10px rgb(0 0 0 / 20%);');
      $('.subimg2').attr('style', 'background-image:'+'url("'+img03+'"); display:none');

      $('#product_image').attr('src', img0);

      $('.decorheadmain span').text(title0);
      
      $('.decimg img').attr('src', img0);

      $('.main-image').attr('style', 'overflow: hidden; box-shadow: 2px 5px 8px 4px rgba(0, 0, 0, 0.4); border-radius: 50px; background-position: center center; background-image:'+'url("'+img0+'");');
      $('.main-image').attr('data-src', img0);

      //$('.main-image img').attr('src', img0);

      $('#product_image').attr('src', img0);

      $('.close2').addClass('d-none');
      $('.popup-top').removeClass('d-none');
      $('.dds-control2').addClass('d-none');

      if ($(window).width() < 768) {
        $('.cc-popup img').on('click', function(){
           $('#produto img').removeClass('zoomed');
        });
      }

    });

  }

  const subImage = async () => {

    $('.decorsubimg1').on('click', function(){

      var img = $(this).attr('data-img');
      
      $('.product_image').attr('src', img);

      $('.centered-div2').addClass('row justify-content-center d-flex h-100');
      
      $('.centered-div2').fadeIn();

      $('.popup-top').addClass('d-none');

      $('.close2').removeClass('d-none');

      $('.dds-control2').removeClass('d-none');

      $('.left2').addClass('left-key2');
      $('.right2').addClass('right-key2');

      $('.left').removeClass('left-key');
      $('.right').removeClass('right-key');
  
      
    });

  }

  const jQueryGeneral = async () => {

    $("body").keyup(function(e) {
  
      var code = e.keyCode || e.which; //Find the key pressed
    
            if (code == 39) 
            {  
              $('.right-key').click();
              $('.right-key2').click();

              if ($(window).width() < 768) {
                $('#produto img').removeClass('zoomed');
              }

            }
            if(code == 37)
            {
              $('.left-key').click();
              $('.left-key2').click();

              if ($(window).width() < 768) {
                $('#produto img').removeClass('zoomed');
              }

            }
    
    });

  }

  const imageZoom = async () => {

    // $('.close').on('click', function(){

    //     $('.centered-div').fadeOut();
    //     $('.centered-div2').fadeOut();
    //     $('.centered-div2').removeClass('row justify-content-center d-flex h-100');

    // });

    $(".close").on("click", function () {
      $(".centered-div")
        .fadeOut(1000)
        .promise()
        .done(function () {
          $(".centered-div").removeClass(
            "row justify-content-center d-flex h-100"
          );
        });
        $(".centered-div2")
        .fadeOut(1000)
        .promise()
        .done(function () {
          $(".centered-div2").removeClass(
            "row justify-content-center d-flex h-100"
          );
        });
        if ($(window).width() < 768) {
          $('.cc-popup img').on('click', function(){
             $('#produto img').removeClass('zoomed');
          });
        }
    });

    $('.zoom').on('mouseover', function(){
      $('.zoomImg').css('width', '900px');
      $('.zoomImg').css('height', '1400px');
    });

    $('.lb-btn').on('click', function(){

      var img = $(this).attr('data-src');
      $('#product_image').attr('src', img);
      $('.zoomImg').attr('src', img);

      //$('.decimg img').attr('src', img);
      
      $('.centered-div').fadeIn();

      $('.close2').addClass('d-none');
      $('.popup-top').removeClass('d-none');
      $('.dds-control2').addClass('d-none');

      $('.left').addClass('left-key');
      $('.right').addClass('right-key');

      $('.left2').removeClass('left-key2');
      $('.right2').removeClass('right-key2');

      var id = 0;
      $(".cc-popup").empty();
      $('.decorbrandlogosbody img').each(function() {
        id++;
        var src = $(this).attr('src');
        $(".cc-popup").append('<img class id="cct-' + id + '" style="width: 50px; height: 50px; float: left; margin-top: 25px; margin-left: 10px; cursor: pointer; border-radius: 5px;" src="' + src + '"/>');

      });

      $('.cc-popup img:first-child').addClass('active-slide');
      $('.cc-popup img:nth-child(2)').addClass('next');

      if ($(window).width() < 768) {
        $('#produto img').removeClass('zoomed');
      }

    });

    $('.left').on('click', function(){
      $('.previous').click();

      if ($(window).width() < 768) {
        $('#produto img').removeClass('zoomed');
      }

    });

    $('.right').on('click', function(){
      $('.next').click();

      if ($(window).width() < 768) {
        $('#produto img').removeClass('zoomed');
      }

    });

    $('.left2').on('click', function(){
      $('.previous2').click();

      if ($(window).width() < 768) {
        $('#produto img').removeClass('zoomed');
      }

    });

    $('.right2').on('click', function(){
      $('.next2').click();

      if ($(window).width() < 768) {
        $('#produto img').removeClass('zoomed');
      }

    });

    var native_width = 0;
    var native_height = 0;

    $(".magnifier").mousemove(function(e) {
      if (!native_width && !native_height) {
        var image_object = new Image();
        image_object.src = $(".small").attr("src");
        native_width = image_object.width;
        native_height = image_object.height;
      } else {
        var magnify_offset = $(this).offset();
        var mx = e.pageX - magnify_offset.left;
        var my = e.pageY - magnify_offset.top;
  
        if (mx < $(this).width() && my < $(this).height() && mx > 0 && my > 0) {
          $(".large").fadeIn(100);
        } else {
          $(".large").fadeOut(100);
        }
        if ($(".large").is(":visible")) {
          var rx =
            Math.round(
              mx / $(".small").width() * native_width - $(".large").width() / 2
            ) * -1;
          var ry =
            Math.round(
              my / $(".small").height() * native_height - $(".large").height() / 2
            ) * -1;
  
          var bgp = rx + "px " + ry + "px";
  
          var px = mx - $(".large").width() / 2;
          var py = my - $(".large").height() / 2;
  
          var background_url = $('#product_image').attr('src');
          var large_background = "url('"+background_url+"')";
  
          $(".large").css({ background: large_background, left: px, top: py, backgroundPosition: bgp });
  
        }
      }
    });
    
    
    $(".magnifier").mouseleave(function(){
      $(".large").fadeTo("slow", 0);
    });

    $(".magnifier").mouseenter(function(){
          $(".large").fadeTo("slow", 1);
    });

    $('#arm').bind('touchstart', function() {
      $(".large").fadeTo("slow", 0);
    });

    $('#arm').bind('touchend', function() {
      $(".large").fadeTo("slow", 1);
    });

  };


  const getoneprod = async () => {

    console.log('mazin one product hit ')
    const res = await axios.get(`https://alnoormdf.com/alnoor/product-desc/`+product_slug);
    if (res.status === 200) {
      setproductdetails(res.data.data)
      console.log('mazin true status  one product')
      console.log(res)
      console.log(res.data.data.product_name)
      setPageTitle('Al-Noor | ' + res.data.data.product_name)
      setPageMeta(res.data.data.product_short_desc)
      setPageThumb(res.data.data.thumbnail_image)
    }

  };


  const [productdetails1, setproductdetails1] = useState([]);

  var product_cat = productdetails.product_id;
  //alert(product_cat);

  const getoneprod1 = async () => {

    // Object.keys(productdetails).map((i, j) =>  (  

    // ));

      // productdetails.map((productdetail) => {product_cat = productdetail.product_id});

      const res = await axios.get('https://alnoormdf.com/alnoor/product-desc/related-products/'+product_slug)
      setproductdetails1(res.data.data)
      console.log(`https://alnoormdf.com/alnoor/product-desc/related-products/`+product_slug)
      console.log(res.data.data)

  };


 
  
  return (

   


    <div>


      <div className="headerboxshadow">
        <Header />
      </div> 
    

          <div className="decorbody">


          <div className="decortextbody dectexthead decortxtmobile" >

<p className="decorheadmain"  > Décor: <span>{productdetails.product_name}</span></p>

</div>
            <div className="decormainimg">








            <div
            
            >
              <div className="decimg mb-5" style={{marginTop: "28px"}}>

                <div

                  style={{
                    overflow: "hidden",
                    boxShadow: "2px 5px 8px 4px rgba(0, 0, 0, 0.4)",
                    borderRadius: "50px",
                    backgroundImage: `url(${'"https://alnoormdf.com/'+productdetails.product_image+'"'})`,
                    backgroundPosition: "center center",
                  }}

                    className="lb-btn main-image"

                    data-src={'https://alnoormdf.com/'+productdetails.product_image}
                    

                  >

                      <img

                        className=""
                        width="100%"
                        height="auto"
                        src={'https://alnoormdf.com/'+productdetails.product_image}
                        alt="Alnoor"
                        objectFit="cover"

                      />

                  </div>


              </div>
            </div>

<div className="centered-div2 img-popop-dl " data-modal="dbl-02" style={{display: "none"}}>

<div className="close" data-modal="dbl-02" style={{width: "0%", position: "absolute", right: "0px", top: "20px"}}>&times;</div>

<div onClick={topImgthumb2} className='dds-control dds-control2 d-none' style={{position: "absolute"}}>
    <i class="left2 fa-solid fa-angle-left"></i>
    <i class="right2 fa-solid fa-angle-right"></i>
  </div>

<div className="img-container img-container-decor justify-content-center align-self-center text-center w-100" >
  {/* <div className="large"></div> */}
  <img alt="img0" className="product_image" src="https://dummyimage.com/600x400/5c5c5c/bdbdbd.jpg" style={{width: "80%", height: "auto", paddingLeft: "10px", paddingRight: "5px"}}/>
</div>


</div>

            <div onMouseEnter={topImgthumb} className="centered-div" style={style1}>

            <div onMouseEnter={topImgthumb2}>

            {/* <div style={{marginTop: "-30px"}} className="close close2 d-none text-white">x</div> */}

              <div onMouseEnter={jQueryGeneral}>
              
              <div onMouseMove={imageChange} className="popup-top" style={{zIndex: "1000", position: "relative", top: "0"}}>

              <div className="close">&times;</div>

              <div onClick={topImgthumb} className='dds-control'>
                <i class="left fa-solid fa-angle-left"></i>
                <i class="right fa-solid fa-angle-right"></i>
              </div>

              <div className="cc-popup pl-3">
              
             

            </div>

              {/* <p className="image-title-popup">{productdetails.product_name}</p> */}
              
              
              </div>

            {/* <div class="magnifier">
              <div class="large"></div>
              <img id="product_image" data-slide="0" class="small img-fluid decorImg" src={"https://alnoormdf.com/"+productdetails.product_image} width="640"/>
            </div> */}

            <center>

              <div className="zoom mt-5 mb-5" id='produto'>
                <img id="product_image" className="my-image decorImg" src="https://res.cloudinary.com/active-bridge/image/upload/slide1.jpg" />
                <div id="target"></div>
              </div>

            </center>

            </div>
            </div>
            </div>



            {productdetails.product_image4 ? (
                   <div style={{width:"100%",marginLeft:"auto",marginRight:"auto",marginTop:"10px",marginBottom:"10px"}}>
                   <img
                     width="548px"
                     height="226px"
                     src={"https://alnoormdf.com/"+productdetails.product_image4}
                     alt="Alnoor"
                     objectFit="contain"
                   />
                   </div>
                   
                  ) : (
                    ""
                  )}




            {productdetails.product_image3 ? (

              <div  className="decorsubimg" style={{width:"188%"}}>

                {/* <img className="subimgtag1 active-slide2" style={{display: "none"}} src={"https://alnoormdf.com/"+productdetails.product_image2}></img>
                <img className="subimgtag2 next2" style={{display: "none"}} src={"https://alnoormdf.com/"+productdetails.product_image3}></img> */}

                {"https://alnoormdf.com/"+productdetails.product_image2 ? (
                        
                  <div onClick={subImage, subImgFix} onTouchStart={subImage, subImgFix} onMouseOver={subImage} onMouseMove={subImgFix} className="decorsubimg1 subimg1"
                    alt="Alnoor"
                    objectFit="cover"
                    data-img={"https://alnoormdf.com/"+productdetails.product_image2}
                    style={{boxShadow: "0 3px 10px rgb(0 0 0 / 20%)", backgroundImage: `url(${'"'+"https://alnoormdf.com/"+productdetails.product_image2+'"'})`}}
                  ></div>

                ) : (
                  ""
                )}



                {productdetails.product_image3 ? (
                        
                  <div onClick={subImage, subImgFix} onTouchStart={subImage, subImgFix} onMouseOver={subImage} onMouseMove={subImgFix} className="decorsubimg1 subimg2"
                    alt="Alnoor"
                    objectFit="cover"
                    data-img={"https://alnoormdf.com/"+productdetails.product_image3}
                    style={{display: "none", backgroundImage: `url(${'"'+"https://alnoormdf.com/"+productdetails.product_image3+'"'})`}}
                  ></div>
            
                ) : (
                  ""
                )}



              </div>
            ) : (


                <div>

                {productdetails.product_image2 ? (

                  <div  className="decorsubimg" style={{width:"200%"}}>
                    
                    <div onClick={subImage, subImgFix} onTouchStart={subImage, subImgFix} onMouseOver={subImage} onMouseMove={subImgFix} className="decorsubimg1 subimg1"
                      alt="Alnoor"
                      objectFit="cover"
                      data-img={"https://alnoormdf.com/"+productdetails.product_image2}
                      style={{boxShadow: "0 3px 10px rgb(0 0 0 / 20%)", backgroundImage: `url(${'"'+"https://alnoormdf.com/"+productdetails.product_image2+'"'})`}}
                    ></div>

                    {/* <div style={{boxShadow:"none"}} onMouseOver={subImage} onMouseMove={subImgFix} className="lb-btn decorsubimg1 subimg2">

                    </div> */}

                    </div>
              
                  ) : (
                    ""
                  )}



                {/* {"https://alnoormdf.com/"+productdetails.product_image3 ? (
                        
                  <img onMouseOver={subImage} onMouseMove={subImgFix} className="lb-btn decorsubimg1 subimg2"
                    src={"https://alnoormdf.com/"+productdetails.product_image3}
                    alt="Alnoor"
                    objectFit="cover"
                    style={{width:"23.4%"}}
                  />

                ) : (
                  ""
                )} */}


              </div>


              
            )}






            </div>

           
            <div className="decortextbody" >

              <p className="decorheadmain dectextmobdesk"  > Décor: <span>{productdetails.product_name}</span></p>

              <div className="decorsubheadmaindiv" >
              <p className="decorsubheadmain" >Decor Type:</p>
              <p className="decorsubheadmain2" >{productdetails.product_type}</p></div>

              <p className="decortext" >{productdetails.product_short_desc}</p>


              {productdetails.product_finish_img1 ? (
                  <p className="decorsubheadmain3" >Available Finish:</p>
                  ) : (
                    ""
                  )}


              {productdetails.product_finish_img1 ? (
                   <div className="decorbrands">
                
                {productdetails.product_finish_img1 ? (
                   
                   <div className="decorbrandlogos">
     
                   <motion.img
                    whileHover={{ scale:1.2 }}
                    transition={{  ease: "easeIn",duration: 0.2 }}
                     width="100%"
                     height="100%"
                     src={"https://alnoormdf.com/"+productdetails.product_finish_img1}
                     alt="Alnoor"
                     objectFit="cover"
                    
                   />
                   </div>
                  ) : (
                    ""
                  )}

              {productdetails.product_finish_img2 ? (
                   
                   <div className="decorbrandlogos">
     
                   <motion.img
                    whileHover={{ scale:1.2 }}
                    transition={{  ease: "easeIn",duration: 0.2 }}
                     width="100%"
                     height="100%"
                     src={"https://alnoormdf.com/"+productdetails.product_finish_img2}
                     alt="Alnoor"
                     objectFit="cover"
                    
                   />
                   </div>
                  ) : (
                    ""
                  )}

              {productdetails.product_finish_img3 ? (
                   
                   <div className="decorbrandlogos">
     
                   <motion.img
                    whileHover={{ scale:1.2 }}
                    transition={{  ease: "easeIn",duration: 0.2 }}
                     width="100%"
                     height="100%"
                     src={"https://alnoormdf.com/"+productdetails.product_finish_img3}
                     alt="Alnoor"
                     objectFit="cover"
                    
                   />
                   </div>
                  ) : (
                    ""
                  )}

              {productdetails.product_finish_img4 ? (
                   
                   <div className="decorbrandlogos">
     
                   <motion.img
                    whileHover={{ scale:1.2 }}
                    transition={{  ease: "easeIn",duration: 0.2 }}
                     width="100%"
                     height="100%"
                     src={"https://alnoormdf.com/"+productdetails.product_finish_img4}
                     alt="Alnoor"
                     objectFit="cover"
                    
                   />
                   </div>
                  ) : (
                    ""
                  )}

              {productdetails.product_finish_img5 ? (
                   
                   <div className="decorbrandlogos">
     
                   <motion.img
                    whileHover={{ scale:1.2 }}
                    transition={{  ease: "easeIn",duration: 0.2 }}
                     width="100%"
                     height="100%"
                     src={"https://alnoormdf.com/"+productdetails.product_finish_img5}
                     alt="Alnoor"
                     objectFit="cover"
                    
                   />
                   </div>
                  ) : (
                    ""
                  )}
                  
                   
                   </div>
     
                  ) : (
                    ""
                  )}


          <p className="decorsubheadmain3" >Collection Colors:</p>

          <div onMouseMove={imageChange} className="decorbrandlogosbody" style={{width:"100%",display:"flex",flexDirection:"row"}}>

            {/* <img src={"https://alnoormdf.com/"+productdetails.product_image} /> */}

            <div className="m-product">
                <img className="m-image" src={"https://alnoormdf.com/"+productdetails.product_image}></img>
                <div className="m-detail d-none">
                  <span className="m-title">{productdetails.product_name}</span>
                  <span className="m-subimg1">{"https://alnoormdf.com/"+productdetails.product_image2}</span>
                  <span className="m-subimg2">{"https://alnoormdf.com/"+productdetails.product_image3}</span>
                </div>
            </div>


          {Object.keys(productdetails1).map((i, j) =>  (
                


                <div className="r-product">
                    <img className="r-image" src={"https://alnoormdf.com/"+productdetails1[i].product_image}></img>
                    <div className="r-detail d-none">
                      <span className="r-title">{productdetails1[i].product_name}</span>
                      <span className="r-subimg1">{"https://alnoormdf.com/"+productdetails1[i].product_image2}</span>
                      <span className="r-subimg2">{"https://alnoormdf.com/"+productdetails1[i].product_image3}</span>
                    </div>
                </div>

                

            ))} 

          </div>

        </div>

      </div>

    <Footer/>

    </div>
  );
};

export default DecorsDetail;