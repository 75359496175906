import React from "react";

import { useEffect } from "react";

const Curated = () => {
  useEffect(() => {
    var radius = 360;
    var autoRotate = true;
    var rotateSpeed = -60;
    var imgWidth = 160;
    var imgHeight = 260;

    var bgMusicURL =
      "https://api.soundcloud.com/tracks/143041228/stream?client_id=587aa2d384f7333a886010d5f52f302a";
    var bgMusicControls = true;

    setTimeout(init, 100);

    var obox = document.getElementById("drag-container");
    var ospin = document.getElementById("spin-container");
    var aImg = ospin.getElementsByTagName("img");
    var aVid = ospin.getElementsByTagName("video");
    var aEle = [...aImg, ...aVid];

    ospin.style.width = imgWidth + "px";
    ospin.style.height = imgHeight + "px";

    var ground = document.getElementById("ground");
    ground.style.width = radius * 3 + "px";
    ground.style.height = radius * 3 + "px";

    function init(delayTime) {
      for (var i = 0; i < aEle.length; i++) {
        aEle[i].style.transform =
          "rotateY(" +
          i * (360 / aEle.length) +
          "deg) translateZ(" +
          radius +
          "px)";
        aEle[i].style.transition = "transform 1s";
        aEle[i].style.transitionDelay =
          delayTime || (aEle.length - i) / 4 + "s";
      }
    }

    function applyTranform(obj) {
      if (tY > 180) tY = 180;
      if (tY < 0) tY = 0;

      obj.style.transform = "rotateY(" + tX + "deg)";
    }

    //   obj.style.transform = "rotateX(" + -tY + "deg) rotateY(" + tX + "deg)";
    // }

    function playSpin(yes) {
      ospin.style.animationPlayState = yes ? "running" : "paused";
    }

    var sX,
      sY,
      nX,
      nY,
      desX = 0,
      desY = 0,
      tX = 0,
      tY = 10;

    if (autoRotate) {
      var animationName = rotateSpeed > 0 ? "spin" : "spinRevert";
      ospin.style.animation = `${animationName} ${Math.abs(
        rotateSpeed
      )}s infinite linear`;
    }

    if (bgMusicURL) {
      document.getElementById("music-container").innerHTML += `
        <audio src="${bgMusicURL}" ${
        bgMusicControls ? "controls" : ""
      } autoplay loop>    
        <p>If you are reading this, it is because your browser does not support the audio element.</p>
        </audio>
        `;
    }

    document.onpointerdown = function (e) {
      clearInterval(obox.timer);
      e = e || window.event;
      var sX = e.clientX,
        sY = e.clientY;

      this.onpointermove = function (e) {
        e = e || window.event;
        var nX = e.clientX,
          nY = e.clientY;
        desX = nX - sX;
        desY = nY - sY;
        tX += desX * 0.1;
        tY += desY * 0.1;
        applyTranform(obox);
        sX = nX;
        sY = nY;
      };

      this.onpointerup = function (e) {
        obox.timer = setInterval(function () {
          desX *= 0.95;
          desY *= 0.95;
          tX += desX * 0.1;
          tY += desY * 0.1;
          applyTranform(obox);
          playSpin(false);
          if (Math.abs(desX) < 0.5 && Math.abs(desY) < 0.5) {
            clearInterval(obox.timer);
            playSpin(true);
          }
        }, 17);
        this.onpointermove = this.onpointerup = null;
      };

      return false;
    };

    // document.onmousewheel = function(e) {
    //   e = e || window.event;
    //   var d = e.wheelDelta / 20 || -e.detail;
    //   radius += d;
    //   init(1);
    // };
  }, []);

  return (
    <div id="maindiv">
      <div id="drag-container">
        <div id="spin-container">
          {/* <span><img src="../images/c_1.webp" alt="" /><p style={{color:"red"}}>hello</p></span> */}

          <img src="../images/c_1.webp" alt="" ></img>

          <img src="../images/c_2.webp" alt="" usemap="#workmap1"></img>
          <map name="workmap1">
            <area
              shape="rect"
              coords="0,0,159,259"
              alt="Computer"
              href="/decorsdetail/4093"
              target="_blank"
            ></area>
          </map>
          <img src="../images/c_3.webp" alt="" usemap="#workmap2"></img>
          <map name="workmap2">
            <area
              shape="rect"
              coords="0,0,159,259"
              alt="Computer"
              href="/decorsdetail/1013"
              target="_blank"
            ></area>
          </map>
          <img src="../images/c_4.webp" alt="" usemap="#workmap3"></img>
          <map name="workmap3">
            <area
              shape="rect"
              coords="0,0,159,259"
              alt="Computer"
              href="/decorsdetail/1201"
              target="_blank"
            ></area>
          </map>
          <img src="../images/c_5.webp" alt="" usemap="#workmap4"></img>
          <map name="workmap4">
            <area
              shape="rect"
              coords="0,0,159,259"
              alt="Computer"
              href="/decorsdetail/1206"
              target="_blank"
            ></area>
          </map>
          <img src="../images/c_6.webp" alt="" usemap="#workmap5"></img>
          <map name="workmap5">
            <area
              shape="rect"
              coords="0,0,270,350"
              alt="Computer"
              href="/decorsdetail/4095"
              target="_blank"
            ></area>
          </map>
          <img src="../images/c_7.webp" alt="" usemap="#workmap6"></img>
          <map name="workmap6">
            <area
              shape="rect"
              coords="0,0,159,259"
              alt="Computer"
              href="/decorsdetail/s008-bermuda"
              target="_blank"
            ></area>
          </map>
          <img src="../images/c_8.webp" alt="" usemap="#workmap7"></img>
          <map name="workmap7">
            <area
              shape="rect"
              coords="0,0,159,259"
              alt="Computer"
              href="/decorsdetail/s009-neapolis"
              target="_blank"
            ></area>
          </map>
          <img src="../images/c_9.webp" alt="" usemap="#workmap8"></img>
          <map name="workmap8">
            <area
              shape="rect"
              coords="0,0,159,259"
              alt="Computer"
              href="/decorsdetail/s011-pure"
              target="_blank"
            ></area>
          </map>
          <img src="../images/c_10.webp" alt="" usemap="#workmap9"></img>
          <map name="workmap9">
            <area
              shape="rect"
              coords="0,0,159,259"
              alt="Computer"
              href="/decorsdetail/s012-nero-marquina"
              target="_blank"
            ></area>
          </map>
          <img src="../images/c_11.webp" alt="" usemap="#workmap0"></img>
          <map name="workmap0">
            <area
              shape="rect"
              coords="0,0,159,259"
              alt="Computer"
              href="/decorsdetail/s013-flagstaff-oak"
              target="_blank"
            ></area>
          </map>
          <div id="ground"></div>
        </div>

        <div id="music-container"></div>

        <script src="script.js"></script>
      </div>
    </div>
  );
};

export default Curated;
