import { transform } from "framer-motion";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
//import { useSearchParams } from "react-router-dom";

import $ from "jquery";

const style1 = { display: "none" };

const key = 1;

const Decorslist = () => {
  const loadMore = async () => {
    $(".tabNav ul").each(function () {
      var num = $(this).find("li").length;
      if (num > 3) {
        $(this).find("li").addClass("hidden");
        $(this).find("li").addClass("toggle");
        $(this).find("li:first").removeClass("hidden");
        $(this).find("li:first").removeClass("toggle");
        $(this).find("li:first").addClass("toggled");
        $(this).find("li:nth-child(1)").removeClass("hidden");
        $(this).find("li:nth-child(1)").removeClass("toggle");
        $(this).find("li:nth-child(1)").addClass("toggled");
        $(this).find("li:nth-child(2)").removeClass("hidden");
        $(this).find("li:nth-child(2)").removeClass("toggle");
        $(this).find("li:nth-child(2)").addClass("toggled");
        $(this).find("li:nth-child(3)").removeClass("hidden");
        $(this).find("li:nth-child(3)").removeClass("toggle");
        $(this).find("li:nth-child(3)").addClass("toggled");
        $(this).find("li:nth-child(4)").removeClass("hidden");
        $(this).find("li:nth-child(4)").removeClass("toggle");
        $(this).find("li:nth-child(4)").addClass("toggled");
      }
    });

    $(".more").on("click", function () {
      $(".toggle").toggleClass("hidden");
    });
  };

  useEffect(() => {
    $(".lb-btn").on("click", function () {
      var videotitle = $(this).find(".decore-tile-label-text").text();
      $(".video-title").text(videotitle);

      $(".dds-control2").removeClass("d-none");

      //$('#main-video').attr('src', $(this).attr('data-src'));

      const videoSource = $(this).attr("data-src");
      $("#main-video source").attr("src", videoSource);
      $("#main-video")[0].load();

      $(".centered-div").fadeIn();

      $(".centered-div").addClass("row justify-content-center d-flex h-100");

      var slide = $(this).attr("data-slide");
      $(".centered-div video").attr("data-slide", slide);

      $("body").keyup(function (e) {
        var code = e.keyCode || e.which; //Find the key pressed

        if (code == 39) {
          $(".right").click();
        }
        if (code == 37) {
          $(".left").click();
        }
      });
    });

    $(".close").on("click", function () {
      $(".centered-div")
        .fadeOut(1000)
        .promise()
        .done(function () {
          $(".centered-div").removeClass(
            "row justify-content-center d-flex h-100"
          );
        });
    });

    var slide = 1;

    $(".lb-btn").each(function () {
      $(this).attr("data-slide", slide++);
    });

    $(".right").on("click", function () {

      var slide = $(".centered-div video").attr("data-slide");

      var total_slides = $(".lb-btn").length;
      if (slide < total_slides) {
        slide++;
      }

      $(".centered-div video").attr("data-slide", slide);
      var path = $('.lb-btn[data-slide = "' + slide + '"] source').attr("src");

      var videotitle = $('.lb-btn[data-slide = "' + slide + '"]').attr("data-title");

      $(".video-title").text(videotitle);

      $(".centered-div video source").attr("src", path);
      $("#main-video")[0].load();

    });

    $(".left").on("click", function () {
      var slide = $(".centered-div video").attr("data-slide");

      if (slide > 1) {
        slide--;
      }

      $(".centered-div video").attr("data-slide", slide);
      var path = $('.lb-btn[data-slide = "' + slide + '"] source').attr("src");

      var videotitle = $('.lb-btn[data-slide = "' + slide + '"]').attr("data-title");

      $(".video-title").text(videotitle);

      $(".centered-div video source").attr("src", path);
      $("#main-video")[0].load();
    });

    $("input:checkbox").on("change", function () {
      var resultUrl = "";

      var url_all = "https://staging.alnoormdf.com/alnoor/all-products";

      var mc_array = [];
      var sc_array = [];

      var api = "https://staging.alnoormdf.com/api/all-products?";

      mc_array = [];
      sc_array = [];

      $("input:checkbox:checked").each(function () {
        if ($(this).hasClass("m-cat")) {
          mc_array.push($(this).attr("rel"));
        }
        if ($(this).hasClass("s-cat")) {
          sc_array.push($(this).attr("rel"));
        }
      });

      resultUrl =
        api + "c_id=" + mc_array.join(",") + "&sc_id=" + sc_array.join(",");
      //alert(url);

      setUrl(resultUrl);

      console.log(resultUrl);

      if (
        resultUrl ==
        "https://staging.alnoormdf.com/api/all-products?c_id=&sc_id="
      ) {
        resultUrl = "https://staging.alnoormdf.com/alnoor/all-products";
      }

      const getallproducts = async () => {
        const res = await axios.get(resultUrl);
        // console.log(url);
        if (res.status === 200) {
          //console.log(res.data.data[0])
          console.log("Products Data:");
          console.log(res.data.data);
          setallproducts(res.data.data);
          loadMore();
        }
      };

      getallproducts();
    });
  }, []);

  //const [searchParams, setSearchParams] = useSearchParams();

  //const filter = searchParams.get("filter");

  const [allproducts, setallproducts] = useState([]);

  const [allcategories, setallcategories] = useState([]);

  const [url, setUrl] = useState(
    "https://staging.alnoormdf.com/alnoor/all-products"
  );

  useEffect(() => {
    getallproducts();
    getallcategories();
    jQueryFilterTags();
    //filterLink();
  }, []);

  const getallproducts = async () => {
    const res = await axios.get(url);
    // console.log(url);
    if (res.status === 200) {
      //console.log(res.data.data[0])
      console.log("Products Data:");
      console.log(res.data.data);
      setallproducts(res.data.data);
    }
  };

  const getallcategories = async () => {
    const res = await axios.get(
      `https://staging.alnoormdf.com/alnoor/all-categories`
    );
    if (res.status === 200) {
      console.log(res.data.data[0]);
      console.log("Categories Data:");
      console.log(res.data.data);
      //console.log(res)
      setallcategories(res.data.data);
    }
  };

  const jQueryFilterTags = () => {
    $("#filter").keyup(function () {
      //$(".tags input:checkbox").prop('checked', false);
      $(".sub-filters").fadeOut();
      // Retrieve the input field text and reset the count to zero
      var filter = $(this).val(),
        count = 0;

      // Loop through the comment list
      $("li").each(function () {
        // If the list item does not contain the text phrase fade it out
        if ($(this).text().search(new RegExp(filter, "i")) < 0) {
          $(this).fadeOut(0).addClass("hidden");
          $(this).attr("style", "display:none !important");

          // Show the list item if the phrase matches and increase the count by 1
        } else {
          $(this).show().removeClass("hidden");
          //count++;
        }
      });

      // Update the count
      // var numberItems = count;
      // $("#filter-count").text("Number of Comments = " + count);
    });

    $(".h-gloss").on("click", function () {
      if ($(this).find("input:checkbox:checked").length > 0) {
        $(".h-gloss-sf").find('input[type="checkbox"]').prop("checked", false);
        $(".h-gloss-sf").fadeIn();
      } else {
        $(this).prop("checked", false);
        $(".h-gloss-sf").fadeOut();
      }
    });

    $(".tactile").on("click", function () {
      if ($(this).find("input:checkbox:checked").length > 0) {
        $(".tactile-sf").find('input[type="checkbox"]').prop("checked", false);
        $(".tactile-sf").fadeIn();
      } else {
        $(this).prop("checked", false);
        $(".tactile-sf").fadeOut();
      }
    });
  };

  /*
  const filterLink = async () => {
    if (filter === "select") {
      $(".select").find('input[type="checkbox"]').click();
    }

    if (filter === "tactile") {
      $(".tactile").find('input[type="checkbox"]').click();
    }

    if (filter === "scintilla") {
      $(".scintilla").find('input[type="checkbox"]').click();
    }
  };
  */

  useEffect(() => {

    // Update Page title tag  
    document.title = "Al-Noor | Scintilla";

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "Scintilla, our latest creation of premium unicolors that stand out with their scintillating finish using the latest lamination technology, creates a dynamic surface with an eccentric appeal.");

  }, []);

  return (
    <div className="scintilla">
      <div style={{ boxShadow: "-7px 7px 45px 0px rgba(0, 0, 0, 0.1)" }}>
        <Header />
      </div>

      <div>
        <video className="hero-banner-desktop" height="100%" width="100%" loop autoPlay muted>
          <source src="/videos/scintilla/video-banner.mp4" type="video/mp4" />
        </video>

        <video className="hero-banner-mobile" height="100%" width="100%" loop autoPlay muted>
          <source src="/videos/scintilla/video-banner-mobile.mp4" type="video/mp4" />
        </video>

        {/* Scintilla */}
      </div>

      <div className="container text-center mt-5">
        <p
          className="scintilla-text"
          style={{ color: "#000", paddingLeft: "18%", paddingRight: "18%" }}
        >
          Scintilla, our latest creation of premium unicolors that stand out
          with their scintillating finish using the latest lamination
          technology, creates a dynamic surface with an eccentric appeal.
        </p>

        <p
          className="scintilla-text"
          style={{ color: "#000", paddingLeft: "18%", paddingRight: "18%" }}
        >
          The product is defined by a unique uniform metallic surface, when
          combined with Al-Noor Lasani’s Super Gloss and High Gloss finish,
          achieves high-quality accents for any living space.
        </p>

        <div className="scinrilla-boxs mt-5">
          
            <label>
              <img
                style={{
                  border: "2px solid #30302f",
                  borderRadius: "35px",
                  cursor: "pointer",
                }}
                className="mr-5 ml-5"
                height="150"
                width="145"
                src="/images/star.webp"
              ></img>
              <br></br>
              Pearlescent/<br></br>Fluorescent effect
            </label>
          

          
            <label>
              <img
                style={{
                  border: "2px solid #30302f",
                  borderRadius: "35px",
                  cursor: "pointer",
                }}
                className="mr-5 ml-5"
                height="150"
                width="145"
                src="/images/scintilla-sparkle.gif"
              ></img>
              <br></br>
              Sparkling<br></br>Surface
            </label>
          
        </div>
      </div>

      
      <div
        className="scinrilla-tiles"
        style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
      >
        <div
          className="row pt-3 justify-content-center dlist-container tabNav"
          style={{
            width: "100%,",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "40px",
          }}
        >
          <ul className="decorList text-center">

          <li style={{ position: "relative", cursor: "pointer" }}>
              <div
                class="decore-tile-container lb-btn"
                data-src="/videos/scintilla/5.mp4"
                data-title="G001 - White Pearl"
              >
                <video height="100%" loop autoPlay muted>
                  <source src="/videos/scintilla/5.mp4" type="video/mp4" />
                </video>

                <div class="decore-tile-label">
                  <p class="decore-tile-label-text">G001 - White Pearl</p>
                </div>
              </div>
            </li>

            <li style={{ position: "relative", cursor: "pointer" }}>
              <div
                class="decore-tile-container lb-btn"
                data-src="/videos/scintilla/4.mp4"
                data-title="G002 - Sky Grey"
              >
                <video height="100%" loop autoPlay muted>
                  <source src="/videos/scintilla/4.mp4" type="video/mp4" />
                </video>

                <div class="decore-tile-label">
                  <p class="decore-tile-label-text">G002 - Sky Grey</p>
                </div>
              </div>
            </li>

            <li style={{ position: "relative", cursor: "pointer" }}>
              <div
                class="decore-tile-container lb-btn"
                data-src="/videos/scintilla/3.mp4"
                data-title="G003 - Sky Monnal Grey"
              >
                <video height="100%" loop autoPlay muted>
                  <source src="/videos/scintilla/3.mp4" type="video/mp4" />
                </video>

                <div class="decore-tile-label">
                  <p class="decore-tile-label-text">G003 - Sky Monnal Grey</p>
                </div>
              </div>
            </li>

            <li style={{ position: "relative", cursor: "pointer" }}>
              <div
                class="decore-tile-container lb-btn"
                data-src="/videos/scintilla/1.mp4"
                data-title="G004 - Rusty Red"
              >
                <video height="100%" loop autoPlay muted>
                  <source src="/videos/scintilla/1.mp4" type="video/mp4" />
                </video>

                <div class="decore-tile-label">
                  <p class="decore-tile-label-text">G004 - Rusty Red</p>
                </div>
              </div>
            </li>


            <li style={{ position: "relative", cursor: "pointer" }}>
              <div
                class="decore-tile-container lb-btn"
                data-src="/videos/scintilla/2.mp4"
                data-title="G005 - Galaxy Black"
              >
                <video height="100%" loop autoPlay muted>
                  <source src="/videos/scintilla/2.mp4" type="video/mp4" />
                </video>

                <div class="decore-tile-label">
                  <p class="decore-tile-label-text">G005 - Galaxy Black</p>
                </div>
              </div>
            </li>


          </ul>
        </div>
      </div>

      <div
        className="centered-div img-popop-dl "
        data-modal="dbl-02"
        style={{ display: "none" }}
      >
        <div
          className="close"
          data-modal="dbl-02"
          style={{
            width: "0%",
            position: "absolute",
            right: "0px",
            top: "20px",
          }}
        >
          &times;
        </div>

        <div
          className="dds-control dds-control2 d-none"
          style={{ position: "absolute" }}
        >
          <i class="left fa-solid fa-angle-left"></i>
          <i class="right fa-solid fa-angle-right"></i>
        </div>

        <div className="text-center w-100 p-0 text-white video-title-wrapper-mobile">
          <div className="video-title w-100 h5 p-2 mt-2" style={{height: "50px"}}></div>
        </div>

        <div className="img-container img-container-decor justify-content-center align-self-center text-center w-100">

        <div className="text-center w-100 p-0 text-white video-title-wrapper-desktop">
          <div className="video-title w-100 h5 p-2 mt-2" style={{height: "50px"}}></div>
        </div>

          <video
            id="main-video"
            className="product_image"
            
            loop
            autoPlay
            muted
          >
            <source src="/videos/scintilla/1.mp4" type="video/mp4" />
          </video>
          {/* <img alt="img0" className="product_image" src="https://dummyimage.com/600x400/5c5c5c/bdbdbd.jpg" style={{width: "80%", height: "auto", paddingLeft: "10px", paddingRight: "5px"}}/> */}
        </div>
      </div>

      <div className="text-center more" style={{ cursor: "pointer" }}>
        <center>
          <motion.div
            style={{
              border: "2px solid #474b4e",
              borderRadius: "10px",
              padding: "6px",
              width: "19px",
              height: "38px",
            }}
          >
            <motion.div
              style={{
                width: "3px",
                height: "3px",
                borderRadius: "10px",
                backgroundColor: "#474b4e",
              }}
              animate={{
                y: [
                  0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 20, 18, 16, 14, 12, 10,
                  8, 6, 4, 2, 0,
                ],
                x: [
                  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                  0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                ],
              }}
              transition={{ duration: 2.5, yoyo: Infinity }}
            ></motion.div>
          </motion.div>

          <i
            style={{ fontSize: "20px", rotate: "90deg", marginLeft: "-1px" }}
            class="fa-solid fa-angle-right fa-angle-right2"
          ></i>
        </center>
      </div>

      <Footer />
    </div>
  );
};

export default Decorslist;
