import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { SSRProvider, ThemeProvider } from 'react-bootstrap';
import './fonts/AvenirLTStd-Black.otf';
import './fonts/AvenirLTStd-Book.otf';
import './fonts/AvenirLTStd-Roman.otf';
import './fonts/CenturyGothic.ttf';



ReactDOM.render(


  <BrowserRouter>
  <ThemeProvider
  breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
  
>
<SSRProvider>
<App />
</SSRProvider>
</ThemeProvider>
</BrowserRouter>,
 
 
 
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
