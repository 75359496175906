import React , { useEffect, useState } from "react";
import Layout from "../components/Layout/Index";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { motion } from "framer-motion";

import axios from 'axios';

import $ from "jquery";

const style1 = { display: "none" };

const imageZoom = async () => {

  $(".sec1dd img").on("click", function () {
    var src = $(this).attr("src");
    $(".product_image").attr("src", src);
    $('.centered-div').addClass('row justify-content-center d-flex h-100');
    $(".centered-div").fadeIn();

    var slide = $(this).attr("data-slide");
    $(".centered-div img").attr("data-slide", slide);

    //alert(src);
  });

  $(".sec1dd .map-data").on("click", function () {
    var usemap_id = $(this).attr("data-usemap");
    $(".product_image").attr("usemap", "#" + usemap_id);

    $(".slide-container map").attr("name", usemap_id);

    $(".product_image").attr("style", "height:auto; width:100%; zoom: auto;");

    // First pointer

    var mc1Top = $(this).find(".map-coordinates").find(".mc1-top").text() + "%";
    var mc1Left =
      $(this).find(".map-coordinates").find(".mc1-left").text() + "%";
    var mc1_link = $(this).find(".map-coordinates").find(".mc1-link").text();
    var mc1_title = $(this).find(".map-coordinates").find(".mc1-title").text();

    if (mc1_title != "") {
      $("#popover-btn").attr(
        "style",
        "left:" +
          mc1Left +
          "; top:" +
          mc1Top +
          ";"
      );
      $("#popover-btn").html(
        "<span class='pointer'><div class='pointer-circle'></div></span><a style='text-decoration: none;' href='" + mc1_link + "' target='_blank'><div class='popover' style='display:none;'><div class='popover-inner'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div><br><div class='popover-content'>" +
          mc1_title +
          "</div></div></div></a>"
      );

      if ($(window).width() < 768) {

        $('#popover-btn').find('a').removeAttr('target');
        $('#popover-btn').find('a').addClass('isDisabled');
        $('#popover-btn').on('click', function(){
          $(this).find('a').removeClass('isDisabled');
        });

      }
      
    } else {
      $("#popover-btn").attr("style", "display:none");
    }

    // Second pointer

    var mc2Top = $(this).find(".map-coordinates").find(".mc2-top").text() + "%";
    var mc2Left =
      $(this).find(".map-coordinates").find(".mc2-left").text() + "%";
    var mc2_link = $(this).find(".map-coordinates").find(".mc2-link").text();
    var mc2_title = $(this).find(".map-coordinates").find(".mc2-title").text();

    if (mc2_title != "") {
      $("#popover-btn2").attr(
        "style",
        "left:" +
          mc2Left +
          "; top:" +
          mc2Top +
          ";"
      );
      $("#popover-btn2").html(
        "<span class='pointer'><div class='pointer-circle'></div></span><a style='text-decoration: none;' href='" + mc2_link + "' target='_blank'><div class='popover' style='display:none;'><div class='popover-inner'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div><br><div class='popover-content'>" +
          mc2_title +
          "</div></div></div></a>"
      );

      if ($(window).width() < 768) {

        $('#popover-btn2').find('a').removeAttr('target');
        $('#popover-btn2').find('a').addClass('isDisabled');
        $('#popover-btn2').on('click', function(){
          $(this).find('a').removeClass('isDisabled');
        });

      }
      
    } else {
      $("#popover-btn2").attr("style", "display:none");
    }

    // Third pointer

    var mc3Top = $(this).find(".map-coordinates").find(".mc3-top").text() + "%";
    var mc3Left =
      $(this).find(".map-coordinates").find(".mc3-left").text() + "%";
    var mc3_link = $(this).find(".map-coordinates").find(".mc3-link").text();
    var mc3_title = $(this).find(".map-coordinates").find(".mc3-title").text();

    if (mc3_title != "") {
      $("#popover-btn3").attr(
        "style",
        "left:" +
          mc3Left +
          "; top:" +
          mc3Top +
          ";"
      );
      $("#popover-btn3").html(
        "<span class='pointer'><div class='pointer-circle'></div></span><a href='" + mc3_link + "' target='_blank'><div class='popover' style='display:none;'><div class='popover-inner'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div><br><div class='popover-content'>" +
          mc3_title +
          "</div></div></div></a>"
      );

      if ($(window).width() < 768) {

        $('#popover-btn3').find('a').removeAttr('target');
        $('#popover-btn3').find('a').addClass('isDisabled');
        $('#popover-btn3').on('click', function(){
          $(this).find('a').removeClass('isDisabled');
        });

      }
      
    } else {
      $("#popover-btn3").attr("style", "display:none");
    }
  });

  // $(".close").on("click", function () {
  //   $('.centered-div').removeClass('row justify-content-center d-flex h-100');
  //   $(".centered-div").fadeOut();
  // });

  $(".close").on("click", function () {
    $(".centered-div")
      .fadeOut(1000)
      .promise()
      .done(function () {
        $(".centered-div").removeClass(
          "row justify-content-center d-flex h-100"
        );
      });
  });

  
};

const OurDesignStudios = () => {
  useEffect(() => {
    $(document).ready(function () {
      $(".absolute").on("mouseenter", function () {
        $(this).find(".popover").fadeIn();
      });

      $(".absolute").on("mouseleave", function () {
        $(this).find(".popover").fadeOut();
      });
    });
  }, []);

  useEffect(() => {
    
    loadMore();

    $(".right").on("click", function () {
      var slide = $(".centered-div img").attr("data-slide");
      var total_slides = $(".sec1dd img").length;
  
      if (slide < total_slides) {
        slide++;
      }
  
      $(".centered-div img").attr("data-slide", slide);
      var path = $('.sec1dd img[data-slide = "' + slide + '"]').attr("src");
      var usemap_id = slide;
      $(".centered-div img").attr("src", path);
      $(".centered-div img").attr("id", usemap_id);
  
      $(".product_image").attr("style", "height:auto; width:100%; zoom: auto;");
  
      // First pointer
  
      var mc1Top =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc1-top")
          .text() + "%";
      var mc1Left =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc1-left")
          .text() + "%";
      var mc1_link = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc1-link")
        .text();  
      var mc1_title = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc1-title")
        .text();
  
      if (mc1_title != "") {
        $("#popover-btn").attr(
          "style",
          "left:" +
            mc1Left +
            "; top:" +
            mc1Top +
            ";"
        );
        $("#popover-btn").html(
          "<span class='pointer'><div class='pointer-circle'></div></span><a style='text-decoration: none;' href='" + mc1_link + "' target='_blank'><div class='popover' style='display:none;'><div class='popover-inner'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div><br><div class='popover-content'>" +
          mc1_title +
          "</div></div></div></a>"
        );
  
        if ($(window).width() < 768) {
  
          $('#popover-btn').find('a').removeAttr('target');
          $('#popover-btn').find('a').addClass('isDisabled');
          $('#popover-btn').on('click', function(){
            $(this).find('a').removeClass('isDisabled');
          });
  
        }
        
      } else {
        $("#popover-btn").attr("style", "display:none");
      }
  
      // Second pointer
  
      var mc2Top =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc2-top")
          .text() + "%";
      var mc2Left =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc2-left")
          .text() + "%";
      var mc2_link = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc2-link")
        .text();
      var mc2_title = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc2-title")
        .text();
  
      if (mc2_title != "") {
        $("#popover-btn2").attr(
          "style",
          "left:" +
            mc2Left +
            "; top:" +
            mc2Top +
            ";"
        );
        $("#popover-btn2").html(
          "<span class='pointer'><div class='pointer-circle'></div></span><a style='text-decoration: none;' href='" + mc2_link + "' target='_blank'><div class='popover' style='display:none;'><div class='popover-inner'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div><br><div class='popover-content'>" +
          mc2_title +
          "</div></div></div></a>"
        );
        
        if ($(window).width() < 768) {
  
          $('#popover-btn2').find('a').removeAttr('target');
          $('#popover-btn2').find('a').addClass('isDisabled');
          $('#popover-btn2').on('click', function(){
            $(this).find('a').removeClass('isDisabled');
          });
  
        }
  
      } else {
        $("#popover-btn2").attr("style", "display:none");
      }
  
      // Third pointer
  
      var mc3Top =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc3-top")
          .text() + "%";
      var mc3Left =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc3-left")
          .text() + "%";
      var mc3_link = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc3-link")
        .text();
      var mc3_title = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc3-title")
        .text();
  
      if (mc3_title != "") {
        $("#popover-btn3").attr(
          "style",
          "left:" +
            mc3Left +
            "; top:" +
            mc3Top +
            ";"
        );
        $("#popover-btn3").html(
          "<span class='pointer'><div class='pointer-circle'></div></span><a style='text-decoration: none;' href='" + mc3_link + "' target='_blank'><div class='popover' style='display:none;'><div class='popover-inner'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div><br><div class='popover-content'>" +
          mc3_title +
          "</div></div></div></a>"
        );
  
        if ($(window).width() < 768) {
  
          $('#popover-btn3').find('a').removeAttr('target');
          $('#popover-btn3').find('a').addClass('isDisabled');
          $('#popover-btn3').on('click', function(){
            $(this).find('a').removeClass('isDisabled');
          });
  
        }
        
      } else {
        $("#popover-btn3").attr("style", "display:none");
      }
    });
  
    $(".left").on("click", function () {
      var slide = $(".centered-div img").attr("data-slide");
  
      if (slide > 1) {
        slide--;
      }
  
      $(".centered-div img").attr("data-slide", slide);
      var path = $('.sec1dd img[data-slide = "' + slide + '"]').attr("src");
      var usemap_id = slide;
      $(".centered-div img").attr("src", path);
      $(".centered-div img").attr("id", usemap_id);
  
      $(".product_image").attr("style", "height:auto; width:100%; zoom: auto;");
  
      // First pointer
  
      var mc1Top =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc1-top")
          .text() + "%";
      var mc1Left =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc1-left")
          .text() + "%";
      var mc1_link = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc1-link")
        .text();
      var mc1_title = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc1-title")
        .text();
  
      if (mc1_title != "") {
        $("#popover-btn").attr(
          "style",
          "left:" +
            mc1Left +
            "; top:" +
            mc1Top +
            ";"
        );
        $("#popover-btn").html(
          "<span class='pointer'><div class='pointer-circle'></div></span><a style='text-decoration: none;' href='" + mc1_link + "' target='_blank'><div class='popover' style='display:none;'><div class='popover-inner'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div><br><div class='popover-content'>" +
          mc1_title +
          "</div></div></div></a>"
        );
  
        if ($(window).width() < 768) {
  
          $('#popover-btn').find('a').removeAttr('target');
          $('#popover-btn').find('a').addClass('isDisabled');
          $('#popover-btn').on('click', function(){
            $(this).find('a').removeClass('isDisabled');
          });
  
        }
        
      } else {
        $("#popover-btn").attr("style", "display:none");
      }
  
      // Second pointer
  
      var mc2Top =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc2-top")
          .text() + "%";
      var mc2Left =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc2-left")
          .text() + "%";
      var mc2_link = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc2-link")
        .text();
      var mc2_title = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc2-title")
        .text();
  
      if (mc2_title != "") {
        $("#popover-btn2").attr(
          "style",
          "left:" +
            mc2Left +
            "; top:" +
            mc2Top +
            ";"
        );
        $("#popover-btn2").html(
          "<span class='pointer'><div class='pointer-circle'></div></span><a style='text-decoration: none;' href='" + mc2_link + "' target='_blank'><div class='popover' style='display:none;'><div class='popover-inner'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div><br><div class='popover-content'>" +
          mc2_title +
          "</div></div></div></a>"
        );
  
        if ($(window).width() < 768) {
  
          $('#popover-btn2').find('a').removeAttr('target');
          $('#popover-btn2').find('a').addClass('isDisabled');
          $('#popover-btn2').on('click', function(){
            $(this).find('a').removeClass('isDisabled');
          });
  
        }
        
      } else {
        $("#popover-btn2").attr("style", "display:none");
      }
  
      // Third pointer
  
      var mc3Top =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc3-top")
          .text() + "%";
      var mc3Left =
        $("#mdd" + usemap_id)
          .find(".map-coordinates")
          .find(".mc3-left")
          .text() + "%";
      var mc3_link = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc3-link")
        .text();
      var mc3_title = $("#mdd" + usemap_id)
        .find(".map-coordinates")
        .find(".mc3-title")
        .text();
  
      if (mc3_title != "") {
        $("#popover-btn3").attr(
          "style",
          "left:" +
            mc3Left +
            "; top:" +
            mc3Top +
            ";"
        );
        $("#popover-btn3").html(
          "<span class='pointer'><div class='pointer-circle'></div></span><a style='text-decoration: none;' href='" + mc3_link + "' target='_blank'><div class='popover' style='display:none;'><div class='popover-inner'><div class='popover-heading'>View</div><div class='float-right' style='color:#000; margin-top: 1px;'><i class='fa fa-angle-right'></i></div><br><div class='popover-content'>" +
          mc3_title +
          "</div></div></div></a>"
        );
  
        if ($(window).width() < 768) {
  
          $('#popover-btn3').find('a').removeAttr('target');
          $('#popover-btn3').find('a').addClass('isDisabled');
          $('#popover-btn3').on('click', function(){
            $(this).find('a').removeClass('isDisabled');
          });
  
        }
        
      } else {
        $("#popover-btn3").attr("style", "display:none");
      }
    });
  
    // $('.swiper-button-next, .swiper-button-prev').attr('style', 'display:none');
  
    $("body").keyup(function (e) {
      var code = e.keyCode || e.which; //Find the key pressed
  
      if (code == 39) {
        $(".right").click();
      }
      if (code == 37) {
        $(".left").click();
      }
    });
    
    if ($(window).width() < 768) {

      $('a').removeAttr('target');

    }

  }, []);

  // const loadMore = async () => {

  //   $('.more').on('click', function() {

  //       $('.toggle').toggleClass('hidden');

  //   });

  // };

  const [page, setPage] = useState(1);
  const [alldiaries, setalldiaries] = useState([]);
  const [url, setUrl] = useState("https://alnoormdf.com/api/diaries?");

  const diaries = async () => {
    const res = await axios.get(`${url}&page=${page}`);
    if (res.status === 200) {
      console.log(`${url}&page=${page}`);
      console.log('Design Diaries Data:');
      console.log(res.data.data);
      setalldiaries((prevProducts) => [...prevProducts, ...res.data.data]);
      imageZoom();
    }
  };

  const loadMore = async () => {
   
    setPage((page) => page + 1);
    diaries();

  };

  useEffect(() => {

    // Update Page title tag  
    document.title = "Al-Noor | Design Diaries";

    // Select the meta tag with a name attribute of "property"
    const socialPageTitle = document.querySelector('meta[property="og:title"]');

    // Update the content attribute of the meta tag
    socialPageTitle.setAttribute('content', "Al-Noor | Design Diaries");

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "Browse our design diaries and take a look at our decors applied in different ways and various places.");

  }, []);


  ///////////////////////
  

  //////////////////////


  const elements = Object.keys(alldiaries).map((key) => {
    return (
      <div data-id={alldiaries[key].product_id}>
        <div
          id={"mdd" + alldiaries[key].id}
          className="map-data toggle"
          data-usemap={alldiaries[key].id}
        >
          <img
            src={alldiaries[key].dd_image_path}
            className="w-100 h-100 p-2"
            data-slide={alldiaries[key].id}
            alt={alldiaries[key].dd_name}
            title={alldiaries[key].dd_name}
          />
          <div className="map-coordinates">
            <span className="mc1-top">{alldiaries[key].dd_mc1_top}</span>
            <span className="mc1-left">{alldiaries[key].dd_mc1_left}</span>
            <span className="mc1-link">{alldiaries[key].dd_mc1_link}</span>
            <span className="mc1-title">{alldiaries[key].dd_mc1_title}</span>
            <span className="mc2-top">{alldiaries[key].dd_mc2_top}</span>
            <span className="mc2-left">{alldiaries[key].dd_mc2_left}</span>
            <span className="mc2-link">{alldiaries[key].dd_mc2_link}</span>
            <span className="mc2-title">{alldiaries[key].dd_mc2_title}</span>
            <span className="mc3-top">{alldiaries[key].dd_mc3_top}</span>
            <span className="mc3-left">{alldiaries[key].dd_mc3_left}</span>
            <span className="mc3-link">{alldiaries[key].dd_mc3_link}</span>
            <span className="mc3-title">{alldiaries[key].dd_mc3_title}</span>
          </div>
        </div>
      </div>
    );
  });
  
  const wrappedElements = [];
  const groupSize = 4;
  const columnCount = 3; // Number of default columns
  
  for (let i = 0; i < elements.length; i += groupSize) {
    const group = elements.slice(i, i + groupSize);
    const columnIndex = i / groupSize % columnCount; // Calculate the column index
  
    if (!wrappedElements[columnIndex]) {
      wrappedElements[columnIndex] = [];
    }
  
    wrappedElements[columnIndex].push(
      <div className="d-flex flex-column" key={`group-${i / groupSize}`}>
        {group}
      </div>
    );
  }
  
  const columns = wrappedElements.map((column, index) => {
    return (
      <div className={`column-${index + 1}`} key={`column-${index + 1}`}>
        {column}
      </div>
    );
  });
  
  
  
  

///////////////////////////////////////////


  return (

    <>
      <div className="headerboxshadow">
        <Header />
      </div>

      {/* <img
        src="/images/headdow.jpg"
        alt="Alnoor"
        objectFit="cover"
      /> */}

      <p className="mainheaddd">Design Diaries</p>

      <div className="container-fluid sec1dd pr-4">

      <div className="d-flex flex-row flex-wrap justify-content-center">

      <>{columns}</>;


      {/* {Object.keys(alldiaries).map((i, j) => (
        <React.Fragment key={i}>
          {(j + 1) % 4 !== 0 && (
            <div className="d-flex flex-column">
              <div data-id={alldiaries[i].product_id}>
                <div
                  id={"mdd" + alldiaries[i].id}
                  className="map-data toggle"
                  data-usemap={alldiaries[i].id}
                >
                  <img
                    src={alldiaries[i].dd_image_path}
                    className="w-100 h-100 m-2 p-2"
                    data-slide={alldiaries[i].id}
                    alt={alldiaries[i].dd_name}
                    title={alldiaries[i].dd_name}
                  />
                  <div className="map-coordinates">
                    <span className="mc1-top">{alldiaries[i].dd_mc1_top}</span>
                    <span className="mc1-left">{alldiaries[i].dd_mc1_left}</span>
                    <span className="mc1-link">{alldiaries[i].dd_mc1_link}</span>
                    <span className="mc1-title">{alldiaries[i].dd_mc1_title}</span>
                    <span className="mc2-top">{alldiaries[i].dd_mc2_top}</span>
                    <span className="mc2-left">{alldiaries[i].dd_mc2_left}</span>
                    <span className="mc2-link">{alldiaries[i].dd_mc2_link}</span>
                    <span className="mc2-title">{alldiaries[i].dd_mc2_title}</span>
                    <span className="mc3-top">{alldiaries[i].dd_mc3_top}</span>
                    <span className="mc3-left">{alldiaries[i].dd_mc3_left}</span>
                    <span className="mc3-link">{alldiaries[i].dd_mc3_link}</span>
                    <span className="mc3-title">{alldiaries[i].dd_mc3_title}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(j + 1) % 4 === 0 && (
            <div data-id={alldiaries[i].product_id}>
              <div
                id={"mdd" + alldiaries[i].id}
                className="map-data toggle"
                data-usemap={alldiaries[i].id}
              >
                <img
                  src={alldiaries[i].dd_image_path}
                  className="w-100 h-100 m-2 p-2"
                  data-slide={alldiaries[i].id}
                  alt={alldiaries[i].dd_name}
                  title={alldiaries[i].dd_name}
                />
                <div className="map-coordinates">
                  <span className="mc1-top">{alldiaries[i].dd_mc1_top}</span>
                  <span className="mc1-left">{alldiaries[i].dd_mc1_left}</span>
                  <span className="mc1-link">{alldiaries[i].dd_mc1_link}</span>
                  <span className="mc1-title">{alldiaries[i].dd_mc1_title}</span>
                  <span className="mc2-top">{alldiaries[i].dd_mc2_top}</span>
                  <span className="mc2-left">{alldiaries[i].dd_mc2_left}</span>
                  <span className="mc2-link">{alldiaries[i].dd_mc2_link}</span>
                  <span className="mc2-title">{alldiaries[i].dd_mc2_title}</span>
                  <span className="mc3-top">{alldiaries[i].dd_mc3_top}</span>
                  <span className="mc3-left">{alldiaries[i].dd_mc3_left}</span>
                  <span className="mc3-link">{alldiaries[i].dd_mc3_link}</span>
                  <span className="mc3-title">{alldiaries[i].dd_mc3_title}</span>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ))} */}

      </div>


        <div className="">

          {/* {Object.keys(alldiaries).map((i, j) =>  (


              <div className="col-md-4" data-id={alldiaries[i].product_id}>

                  <div id={"mdd"+alldiaries[i].id} className="map-data toggle" data-usemap={alldiaries[i].id}>
                    <img
                      src={alldiaries[i].dd_image_path}
                      className="w-100 h-100 m-2 p-2"
                      data-slide={alldiaries[i].id}
                      alt={alldiaries[i].dd_name}
                      title={alldiaries[i].dd_name}
                    ></img>
                    <div className="map-coordinates">
                      <span className="mc1-top">{alldiaries[i].dd_mc1_top}</span>
                      <span className="mc1-left">{alldiaries[i].dd_mc1_left}</span>

                      <span className="mc1-link">{alldiaries[i].dd_mc1_link}</span>
                      <span className="mc1-title">{alldiaries[i].dd_mc1_title}</span>

                      <span className="mc2-top">{alldiaries[i].dd_mc2_top}</span>
                      <span className="mc2-left">{alldiaries[i].dd_mc2_left}</span>

                      <span className="mc2-link">{alldiaries[i].dd_mc2_link}</span>
                      <span className="mc2-title">{alldiaries[i].dd_mc2_title}</span>

                      <span className="mc3-top">{alldiaries[i].dd_mc3_top}</span>
                      <span className="mc3-left">{alldiaries[i].dd_mc3_left}</span>

                      <span className="mc3-link">{alldiaries[i].dd_mc3_link}</span>
                      <span className="mc3-title">{alldiaries[i].dd_mc3_title}</span>
                    </div>
                  </div>

                


              </div>

              ))} */}

        </div>

      </div>

      <br></br>

      <br></br>

      <div
        className="centered-div img-popop-dl"
        data-modal="dbl-02"
        style={style1}
      >
        <div className="close" data-modal="dbl-02" style={{width: "0%", position: "absolute", right: "0", top: "20px"}}>
          x
        </div>

        <div className="dds-control">
          <i className="left fa-solid fa-angle-left"></i>
          <i className="right fa-solid fa-angle-right"></i>
        </div>

        <center>
          <div class="slide-container justify-content-center align-self-center text-center" style={{marginTop: "-15px"}}>
            <div class="img_viewer relative map">
              <img
                className="product_image"
                id="test"
                src="https://farm4.staticflickr.com/3804/33589584740_b0fbdcd4aa_z.jpg"
              />

              <div class="absolute" id="popover-btn"></div>
              <div class="absolute" id="popover-btn2"></div>
              <div class="absolute" id="popover-btn3"></div>
            </div>
          </div>
        </center>
      </div>

      <div className="text-center more" style={{cursor: "pointer"}} onClick={loadMore}>
      <center>
    
        <motion.div style={{border: "2px solid #474b4e", borderRadius: "10px", padding: "6px", width: "19px", height: "38px"}}>
          <motion.div
            style={{width: "3px", height: "3px", borderRadius: "10px", backgroundColor: "#474b4e"}}
            animate={{
              y: [
                0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 20, 18, 16, 14, 12, 10,
                8, 6, 4, 2, 0
              ],
              x: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0
              ],
            }}
            transition={{ duration: 2.5, yoyo: Infinity }}
          ></motion.div>
        </motion.div>

        <i style={{fontSize: "20px", rotate: "90deg", marginLeft: "-0.1px"}} class="fa-solid fa-angle-right fa-angle-right2"></i>

      </center>
    </div>

      <Footer />
    </>
  );
};

export default OurDesignStudios;
